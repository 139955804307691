import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import Header from 'src/components/Header';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading, Flex, Box } from 'rebass/styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import ShowCaseCard from 'src/components/ShowCaseCard';
import Footer from 'src/sections/Footer';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const Projects = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      papagouA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/papagou-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      marousiA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/marousi-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      sygrotimaA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/sygrotima-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaB: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-b" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaC: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-c" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      sygrotimaB: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/sygrotima-b" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      sygrotimaC: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/sygrotima-c" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaD: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-d" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaF: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-f" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      chalandriA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/chalandri-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaG: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-g" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      erythreaA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/erythrea-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      chalandriB: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/chalandri-b" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      erythreaB: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/erythrea-b" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
    }
  `);

  const papagouAImages = data.papagouA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.papagouA' }),
    };
  });

  const marousiAImages = data.marousiA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.marousiA' }),
    };
  });

  const vrilissiaAImages = data.vrilissiaA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaA' }),
    };
  });

  const sygrotimaAImages = data.sygrotimaA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.sygrotimaA' }),
    };
  });

  const vrilissiaBImages = data.vrilissiaB.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaB' }),
    };
  });

  const vrilissiaCImages = data.vrilissiaC.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaC' }),
    };
  });

  const sygrotimaBImages = data.sygrotimaB.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.sygrotimaB' }),
    };
  });

  const sygrotimaCImages = data.sygrotimaC.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.sygrotimaC' }),
    };
  });

  const vrilissiaDImages = data.vrilissiaD.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaD' }),
    };
  });

  const vrilissiaFImages = data.vrilissiaF.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaF' }),
    };
  });

  const chalandriAImages = data.chalandriA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.chalandriA' }),
    };
  });

  const vrilissiaGImages = data.vrilissiaG.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaG' }),
    };
  });

  const erythreaAImages = data.erythreaA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.erythreaA' }),
    };
  });

  const chalandriBImages = data.chalandriB.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.chalandriB' }),
    };
  });

  const erythreaBImages = data.erythreaB.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.erythreaB' }),
    };
  });

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <SEO title="Paisios Constructions | Projects" />
      <Helmet />
      <Header />

      <Box
        sx={{
          maxWidth: 960,
          mx: 'auto',
          px: 3,
        }}
      >
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          pt={150}
          mb={5}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="projects.title" />
        </Heading>

        <Flex flexWrap="wrap">
          <ShowCaseCard
            cardImage={data.erythreaB.edges[0].node.childImageSharp.fluid}
            projectImages={erythreaBImages}
            headingText={intl.formatMessage({ id: 'projects.erythreaB' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.erythreaA.edges[0].node.childImageSharp.fluid}
            projectImages={erythreaAImages}
            headingText={intl.formatMessage({ id: 'projects.erythreaA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaG.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaGImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaG' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.chalandriB.edges[0].node.childImageSharp.fluid}
            projectImages={chalandriBImages}
            headingText={intl.formatMessage({ id: 'projects.chalandriB' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaF.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaFImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaF' })}
          ></ShowCaseCard>
          <ShowCaseCard
            buildingComplex="chalandriA"
            cardImage={data.chalandriA.edges[0].node.childImageSharp.fluid}
            projectImages={chalandriAImages}
            headingText={intl.formatMessage({ id: 'projects.chalandriA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.papagouA.edges[0].node.childImageSharp.fluid}
            projectImages={papagouAImages}
            headingText={intl.formatMessage({ id: 'projects.papagouA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.marousiA.edges[0].node.childImageSharp.fluid}
            projectImages={marousiAImages}
            headingText={intl.formatMessage({ id: 'projects.marousiA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaA.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaAImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.sygrotimaA.edges[0].node.childImageSharp.fluid}
            projectImages={sygrotimaAImages}
            headingText={intl.formatMessage({ id: 'projects.sygrotimaA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaB.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaBImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaB' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaC.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaCImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaC' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.sygrotimaB.edges[0].node.childImageSharp.fluid}
            projectImages={sygrotimaBImages}
            headingText={intl.formatMessage({ id: 'projects.sygrotimaB' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.sygrotimaC.edges[0].node.childImageSharp.fluid}
            projectImages={sygrotimaCImages}
            headingText={intl.formatMessage({ id: 'projects.sygrotimaC' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaD.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaDImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaD' })}
          ></ShowCaseCard>
        </Flex>
      </Box>
      <Footer />
    </Layout>
  );
};

export default Projects;
